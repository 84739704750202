import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as HomeActions from "../../../actions/HomeActions";
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Share from '../common/Share';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeAction from '../../../actions/HomeActions'
import AlertMessage from '../common/AlertMessage';
import { toast } from 'react-toastify';
import plus from '../../../assets/regal_images/plus.png'
import check from '../../../assets/regal_images/check.png'
import SwiperCore, { Autoplay, Pagination, Navigation, EffectCreative, Thumbs } from "swiper";
import { Box, CircularProgress, Tooltip, styled } from '@mui/material';
import VideoJS from '../../../player/player'
import videojs from 'video.js';
import animatedGIF from '../../../assets/regal_images/Animation.gif'
import { EffectCoverflow } from 'swiper';
import axios from 'axios';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Modal from '@mui/material/Modal';
import { Base64 } from 'js-base64';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)
            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)
            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)
            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)
            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)
            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)
            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)
            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')
            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }
            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.from(elem, setOption)
    },
    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.to(elem, setOption)
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        const setEndOption = gsapAnimate.getData(elem)
        setEndOption.opacity = 1
        setEndOption.x = 0
        setEndOption.y = 0
        setEndOption.rotate = 0
        setEndOption.scale = 1
        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}

function Slider() {
    const playerRef = React.useRef(null);

    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [slug, setSlug] = useState("")
    const [watchListopen, setWatchlistOpen] = React.useState(false);
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id

    const [save, setSave] = useState(false)
    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { getMovieDetails } = bindActionCreators(HomeAction, dispatch)
    const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
    const [desktop, setDesktop] = useState(false)

    const { setCompleteHours } = MovieAction

    const { getSliderData } = bindActionCreators(HomeAction, dispatch)
    const [popupOpen, setPopupOpen] = useState(false)
    const [selectedMovie, setSelectedMovie] = useState()
    const { removeFromWatchlist } = MovieAction
    const history = useHistory()
    const [sliderData, setSliderData] = useState([])
    const [count, setCount] = useState(-2);
    SwiperCore.use([Autoplay, EffectCoverflow, Thumbs])
    const [animationStart, setAnimationStart] = useState(false)
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [tempData, setTempData] = useState("")
    const isRunningInElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

    let maxMovieWatchFlag = false
    useEffect(() => {
        (async () => {
            let localArr = []
            let userObj = {
                profile_id: profile_id ? profile_id : "null",
                user_id: user_id || "null"
            }
            const sliderRes = await getSliderData(userObj)


            setSliderData(sliderRes)


            // }
            const intervalId = setInterval(() => {
                setHoursLeft(calculateHoursLeft());
            }, 1000);
        })();
    }, [save])
    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleRedirect = (slug, category) => {
        if (category == "Movie") {
            history.push(`${ROUTES.moviedetails}/${slug}`)
        }
        else {
            history.push(`${ROUTES.seriesDetails}/${slug}`)

        }

    }



    return (
        <>



            {
                sliderData?.length ?
                    <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                        {/* <div id="prev5" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next5" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={true}
                            slidesPerView={1.17}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 150,
                                depth: 200,
                                modifier: 1.8,
                            }}

                            // initialSlide={1}
                            pagination={{ el: '.swiper-pagination', clickable: true }}
                            navigation={{
                                nextEl: 'swiper-buton-next',
                                prevEl: 'swiper-button-pre',
                                clickable: true
                            }}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            className='swiper-container '

                            autoplay={{
                                delay: 3500,
                            }}
                            onSlideChange={(swiper) => {

                                if (sliderData[count]?.slider_type == "Video") {

                                    swiper.autoplay.stop();
                                    playerRef.current.src(sliderData[count]?.video_url)
                                    playerRef.current.play()
                                    playerRef.current.on('ended', () => {
                                        swiper.autoplay.start();
                                    });

                                }
                                if (count == sliderData?.length - 1)
                                    setCount(-1);
                                else {
                                    setCount((prev) => prev + 1);
                                }
                            }}
                            style={{ marginTop: "70px", marginBottom: "1rem" }}
                        >
                            {
                                sliderData?.map((data, index) => (

                                    <SwiperSlide
                                        // className="slide slick-bg"
                                        key={index}
                                        style={{ backgroundSize: "cover" }}
                                        onClick={() => handleRedirect(data?.movie ? data?.movie?.slug : data?.series?.slug, data?.category_type)}
                                    >
                                        <div>
                                            <img className='home-slider-res' src={IMAGE_ENDPOINT + (data?.web_banner || data?.movie?.movie_poster || data?.series?.series_poster)} alt="" />
                                            {/* <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: "30px",
                                                    left: "30px"
                                                }}>
                                                <h3 class="trending-text big-title text-uppercase mt-0 mb-2">{data?.series?.series_name}</h3>
                                            </div> */}
                                        </div>

                                    </SwiperSlide>
                                ))
                            }


                        </Swiper>

                    </section>
                    :
                    <div style={{ height: "80vh" }}>
                        <div style={{ position: "relative", textAlign: "center", top: "50%" }}>
                            <CircularProgress style={{ color: "#ba000b" }} />
                        </div>
                    </div>
            }


        </>
    )
}
export default Slider